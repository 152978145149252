import clsx from 'clsx';
import { Link, useMatches } from 'react-router-dom';
import './Navbar.css';

type NavbarLink = {
  to: string;
  label: string;
  icon: string;
  root?: boolean;
};

export function Navbar() {
  const links: NavbarLink[] = [
    {
      to: '/',
      label: 'YAML Config Tool',
      icon: 'fa-code',
      root: true,
    },
    { to: '/config/entities', label: 'Entity Configurations', icon: 'fa-building' },
  ];

  const matches = useMatches();
  const isLinkActive = (link: NavbarLink) => {
    const path = matches[matches.length - 1].pathname;
    if (path === '/') { return link.root; }
    if (link.root && path !== '/') { return false; }
    return path.includes(link.to);
  };

  return (
    <nav className="navbar">
      <ul className="navbar__links">
        {links.map((link) => (
          <li className="navbar__links__link" key={link.to}>
            <Link
              to={link.to}
              className={clsx('navbar__links__link__text', {
                'navbar__links__link__text--active': isLinkActive(link),
              })}
            >
              <i className={`fa-solid ${link.icon}`} />
              {' '}
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
