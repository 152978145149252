import clsx from 'clsx';
import { EntityConfigResourceActionType, EntityConfigModuleActionType } from '../../types/EntityConfig';
import './ConfigActions.css';

type ConfigActionsProps<T> = {
  actionType: EntityConfigResourceActionType | EntityConfigModuleActionType;
  renderAction: (resourceAction: T) => React.JSX.Element;
  actions: (T & { key: string })[];
  title: string;
};

export function ConfigActions<T>({
  actionType,
  renderAction,
  actions,
  title,
}: ConfigActionsProps<T>) {
  if (!actions.length) { return null; }
  return (
    <>
      <h3
        className={clsx('resource_actions__title', {
          'resource_actions__title--success': actionType === EntityConfigResourceActionType.CREATE
            || actionType === EntityConfigModuleActionType.ENABLE,
          'resource_actions__title--warning': actionType === EntityConfigResourceActionType.UPDATE
            || actionType === EntityConfigModuleActionType.UPDATE,
          'resource_actions__title--error': actionType === EntityConfigResourceActionType.DELETE
            || actionType === EntityConfigModuleActionType.DISABLE,
        })}
      >
        {title}
      </h3>
      <ul>
        {actions.map((action, i) => (
          <li key={action.key} className={clsx(i !== 0 ? 'nc-l-mt_200_mobile' : '')}>
            {renderAction(action)}
          </li>
        ))}
      </ul>
    </>
  );
}
