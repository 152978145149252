import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  FormField, NCButton, NCInputCheckbox, NCInputText, NCLoadingIndicator, NCWell, useForm,
} from '@daupler/nexus-components';
import { useAuthentication } from '../hooks/useAuthentication';
import { Entity, EntityDetail } from '../types/Entity';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { EntityFormFields } from '../components/EntityFormFields';
import { logger } from '../utils/logger';
import {
  EntityConfigResourceActionType,
  EntityConfigPlanResponse,
  EntityConfigResourceAction,
  EntityConfigModuleActionType,
  EntityConfigModuleAction,
} from '../types/EntityConfig';
import { ConfigAction } from '../views/entities-id-route/ConfigAction';
import { ConfigActions } from '../views/entities-id-route/ConfigActions';

export function EntitiesIdRoute() {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { authToken } = useAuthentication();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const name: FormField<string> = {
    name: 'name',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Name is required',
    value: '',
  };
  const shortName: FormField<string> = {
    name: 'shortName',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Short Name is required',
    value: '',
  };
  const timezone: FormField<string> = {
    name: 'timezone',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Timezone is required',
    value: '',
  };
  const parentEntityId: FormField<string> = {
    name: 'parentEntityId',
    validate: () => true,
    validMessage: '',
    invalidMessage: '',
    value: '',
  };
  const configurationYaml: FormField<string> = {
    name: 'configurationYaml',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Must provide valid Configuration YAML',
    value: '',
  };
  const planHash: FormField<string> = {
    name: 'planHash',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: '',
    value: '',
  };
  const dryRun: FormField<boolean> = {
    name: 'dryRun',
    validate: () => true,
    validMessage: '',
    invalidMessage: '',
    value: false,
  };
  const {
    formState,
    isFormValid,
    onChange,
    resetValues,
    validateField,
    validateForm,
  } = useForm({
    configurationYaml,
    dryRun,
    name,
    parentEntityId,
    planHash,
    shortName,
    timezone,
  });

  const [entity, setEntity] = useState<EntityDetail | null>(null);
  const [timezones, setTimezones] = useState<string[] | null>(null);
  const [entities, setEntities] = useState<Entity[] | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      setIsLoading(true);
      try {
        const [
          entityResponse,
          tzNamesResponse,
          entitiesResponse,
        ] = await Promise.all([
          dauplerApi.current.getEntity(authToken, id),
          dauplerApi.current.getTimezones(authToken),
          dauplerApi.current.getEntities(authToken),
        ]);
        setEntity(entityResponse);
        onChange(formState.name.name, entityResponse.name);
        onChange(formState.shortName.name, entityResponse.shortName);
        onChange(formState.timezone.name, entityResponse.timezone);
        onChange(formState.parentEntityId.name, entityResponse.parentEntities?.[0]?.id ?? '');
        setTimezones(tzNamesResponse);
        setEntities(entitiesResponse);
      } finally {
        setIsLoading(false);
      }
    };
    if (entity) { return; }
    getEntities();
  }, [authToken, entity, formState, id, onChange]);

  const [planResult, setPlanResult] = useState<EntityConfigPlanResponse | null>(null);

  const [isWorking, setIsWorking] = useState(false);
  const [serverError, setServerError] = useState('');
  const onPlan = async () => {
    setServerError('');
    validateField(formState.configurationYaml.name);
    if (!formState.configurationYaml.isValid) { return; }
    if (!authToken) { return; }
    if (!id) { return; }
    try {
      setIsWorking(true);
      const response = await dauplerApi.current.planEntityConfig(
        authToken,
        id.split('-').slice(1, id.split('-').length).join('-').toLowerCase(),
        formState.configurationYaml.value,
      );
      onChange(formState.planHash.name, response.meta.hash);
      setPlanResult(response);
    } catch (err) {
      logger.error('Failed to plan configuration', err);
      if (err instanceof Error) {
        setServerError(err.message);
      }
    } finally {
      setIsWorking(false);
    }
  };
  const apply = async () => {
    setServerError('');
    validateForm();
    if (!isFormValid()) { return; }
    if (!authToken) { return; }
    if (!id) { return; }
    try {
      setIsWorking(true);
      await dauplerApi.current.applyEntityConfig(
        authToken,
        id,
        formState.configurationYaml.value,
        formState.planHash.value,
      );
      resetValues();
    } catch (err) {
      logger.error('Failed to apply configuration', err);
      if (err instanceof Error) {
        setServerError(err.message);
      }
    } finally {
      setIsWorking(false);
    }
  };

  const modulesToEnable = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.ENABLE,
  );
  const modulesToUpdate = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.UPDATE,
  );
  const modulesToDisable = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.DISABLE,
  );

  const resourcesToCreate = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.CREATE,
  );
  const resourcesToUpdate = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.UPDATE,
  );
  const resourcesToDelete = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.DELETE,
  );

  return (
    <div className="nc-l-pa_300_mobile">
      <h1 className="nc-t-h1_medium_mobile nc-t-grey_900">Entity</h1>
      <p className="nc-t-body_regular_mobile nc-t-grey_600">{id}</p>
      {serverError ? (
        <p className="nc-t-center nc-t-body_regular_mobile nc-t-error nc-l-mt_200_mobile">{serverError}</p>
      ) : null}

      {isLoading ? (<NCLoadingIndicator blockUi label="Loading Entity" />) : null}

      {!isLoading ? (
        <>
          <EntityFormFields
            formState={formState}
            onChange={onChange}
            timezones={timezones}
            entities={entities}
            disabled
          />

          {planResult ? (
            <>
              <h2 className="nc-t-body_medium_mobile nc-l-mb_utilities_50 nc-l-mt_200_mobile">Plan Ouput</h2>
              <NCWell>
                <ConfigActions<EntityConfigModuleAction>
                  actionType={EntityConfigModuleActionType.ENABLE}
                  renderAction={(moduleAction) => (
                    <ConfigAction
                      itemKey={moduleAction.module_type}
                      details={JSON.stringify(moduleAction)}
                      actionType={EntityConfigModuleActionType.ENABLE}
                    />
                  )}
                  actions={modulesToEnable}
                  title="Enable the following modules:"
                />
                <ConfigActions<EntityConfigModuleAction>
                  actionType={EntityConfigModuleActionType.UPDATE}
                  renderAction={(moduleAction) => (
                    <ConfigAction
                      itemKey={moduleAction.module_type}
                      details={JSON.stringify(moduleAction)}
                      actionType={EntityConfigModuleActionType.UPDATE}
                    />
                  )}
                  actions={modulesToUpdate}
                  title="Update the following modules:"
                />
                <ConfigActions<EntityConfigModuleAction>
                  actionType={EntityConfigModuleActionType.DISABLE}
                  renderAction={(moduleAction) => (
                    <ConfigAction
                      itemKey={moduleAction.module_type}
                      details={JSON.stringify(moduleAction)}
                      actionType={EntityConfigModuleActionType.DISABLE}
                    />
                  )}
                  actions={modulesToDisable}
                  title="Disable the following modules:"
                />

                <ConfigActions<EntityConfigResourceAction>
                  actionType={EntityConfigResourceActionType.CREATE}
                  renderAction={(resourceAction: EntityConfigResourceAction) => (
                    <ConfigAction
                      itemKey={resourceAction.key}
                      type={resourceAction.resource_type}
                      displayName={resourceAction.planned_resource?.display_name ?? ''}
                      details={JSON.stringify(resourceAction)}
                      actionType={EntityConfigResourceActionType.CREATE}
                    />
                  )}
                  actions={resourcesToCreate}
                  title="Create the following resources:"
                />
                <ConfigActions<EntityConfigResourceAction>
                  actionType={EntityConfigResourceActionType.UPDATE}
                  renderAction={(resourceAction) => (
                    <ConfigAction
                      itemKey={resourceAction.key}
                      type={resourceAction.resource_type}
                      displayName={resourceAction.planned_resource?.display_name ?? ''}
                      details={JSON.stringify(resourceAction)}
                      actionType={EntityConfigResourceActionType.UPDATE}
                    />
                  )}
                  actions={resourcesToUpdate}
                  title="Update the following resources:"
                />
                <ConfigActions<EntityConfigResourceAction>
                  actionType={EntityConfigResourceActionType.DELETE}
                  renderAction={(resourceAction) => (
                    <ConfigAction
                      itemKey={resourceAction.key}
                      type={resourceAction.resource_type}
                      displayName={resourceAction.planned_resource?.display_name ?? ''}
                      details={JSON.stringify(resourceAction, undefined, 2)}
                      actionType={EntityConfigResourceActionType.DELETE}
                    />
                  )}
                  actions={resourcesToDelete}
                  title="Delete the following resources:"
                />
              </NCWell>
              <div className="nc-flex nc-flex--justify_between nc-flex--align_end">
                <p className="nc-t-sub_text_light nc-t-grey_700 nc-l-mt_utilities_50_mobile">
                  {`Plan hash: ${formState.planHash.value}`}
                </p>
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  color={NCButton.colors.PRIMARY}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  type="submit"
                  onClick={() => {
                    setPlanResult(null);
                    onChange(formState.planHash.name, '');
                  }}
                  disabled={isWorking}
                >
                  Edit
                </NCButton>
              </div>
              <div className="nc-flex nc-l-mt_200_mobile">
                <NCButton
                  color={NCButton.colors.PRIMARY}
                  appearance={NCButton.appearances.INVERSE}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  type="submit"
                  onClick={apply}
                  disabled={isWorking}
                  className="nc-l-mr_200_mobile"
                >
                  {isWorking ? 'Applying...' : 'Apply'}
                </NCButton>
                <NCInputCheckbox
                  label="Dry Run"
                  name={formState.dryRun.name}
                  checked={formState.dryRun.value}
                  onChange={(event) => onChange(formState.dryRun.name, event.target.checked)}
                />
              </div>
            </>
          ) : (
            <>
              <NCInputText
                multiline
                rows={3}
                name={formState.configurationYaml.name}
                hint={formState.configurationYaml.message}
                isValid={formState.configurationYaml.isValid}
                value={formState.configurationYaml.value}
                className="nc-l-mt_200_mobile"
                onChange={(event) => {
                  onChange(formState.configurationYaml.name, event.target.value);
                  onChange(formState.planHash.name, '');
                }}
                label="Configuration YAML"
              />
              <NCButton
                color={NCButton.colors.PRIMARY}
                appearance={NCButton.appearances.INVERSE}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                onClick={onPlan}
                disabled={isWorking}
                className="nc-l-mt_100_mobile"
              >
                {isWorking ? 'Planning...' : 'Plan'}
              </NCButton>
            </>
          )}
        </>
      ) : null}
    </div>
  );
}
