import { useEffect, useRef, useState } from 'react';
import {
  FormField,
  NCButton,
  useForm,
} from '@daupler/nexus-components';
import { useNavigate } from 'react-router';
import { useAuthentication } from '../hooks/useAuthentication';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import './EntitiesRoute.css';
import { logger } from '../utils/logger';
import { Entity } from '../types/Entity';
import { EntityFormFields } from '../components/EntityFormFields';

export function EntitiesAddEntityRoute() {
  const navigate = useNavigate();

  const [isCreating, setIsCreating] = useState(false);
  const [serverError, setServerError] = useState('');

  const { authToken } = useAuthentication();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const name: FormField<string> = {
    name: 'name',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Name is required',
    value: '',
  };
  const shortName: FormField<string> = {
    name: 'shortName',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Short Name is required',
    value: '',
  };
  const timezone: FormField<string> = {
    name: 'timezone',
    validate: (value) => !!value,
    validMessage: '',
    invalidMessage: 'Timezone is required',
    value: '',
  };
  const parentEntityId: FormField<string> = {
    name: 'parentEntityId',
    validate: () => true,
    validMessage: '',
    invalidMessage: '',
    value: '',
  };
  const {
    formState,
    getFormValues,
    isFormValid,
    onChange,
    validateForm,
  } = useForm({
    name,
    shortName,
    timezone,
    parentEntityId,
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setServerError('');
    validateForm();
    if (!isFormValid()) { return; }
    if (!authToken) { return; }
    try {
      const response = await dauplerApi.current.createEntity(authToken, getFormValues());
      navigate(`/config/entities/${response.id}`);
    } catch (err) {
      logger.error('Failed to add Entity', err);
      if (err instanceof Error) {
        setServerError((err as Error).message);
      }
    } finally {
      setIsCreating(false);
    }
  };

  const [timezones, setTimezones] = useState<string[] | null>(null);
  useEffect(() => {
    const getTimezones = async () => {
      if (!authToken) { return; }
      const tzNames = await dauplerApi.current.getTimezones(authToken);
      setTimezones(tzNames);
    };
    if (timezones) { return; }
    getTimezones();
  }, [authToken, timezones]);

  const [entities, setEntities] = useState<Entity[] | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      const response = await dauplerApi.current.getEntities(authToken);
      setEntities(response);
    };
    if (entities) { return; }
    getEntities();
  }, [authToken, entities]);

  return (
    <form className="nc-l-pa_300_mobile" onSubmit={handleSubmit}>
      <h1 className="nc-t-h1_medium_mobile nc-t-grey_900">Add Entity</h1>
      {serverError ? (
        <p className="nc-t-center nc-t-body_regular_mobile nc-t-error nc-l-mt_200_mobile">{serverError}</p>
      ) : null}

      <EntityFormFields
        formState={formState}
        onChange={onChange}
        timezones={timezones}
        entities={entities}
      />

      <NCButton
        color={NCButton.colors.PRIMARY}
        appearance={NCButton.appearances.INVERSE}
        disabled={isCreating}
        className="nc-l-mt_200_mobile"
        width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
        type="submit"
      >
        {isCreating ? (
          <>Adding Entity...</>
        ) : (
          <>Add Entity</>
        )}
      </NCButton>
    </form>
  );
}
