export enum EntityConfigModuleActionType {
  ENABLE = 'enable',
  UPDATE = 'update',
  DISABLE = 'disable',
}
export enum EntityConfigResourceActionType {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

export type EntityConfigResourceAction = {
  new_data: null;
  old_data: null;
  action_type: EntityConfigResourceActionType;
  resource_id: string;
  planned_hash: string;
  key: string;
  planned_resource: {
    workgroup_id: null;
    display_name: string;
    data: {};
    key: string;
    workgroup_internal_name: null;
    type: string;
  } | null;
  resource_type: string;
};

export type EntityConfigModuleAction = {
  key: string;
  planned_module: null;
  module_type: string;
  action_type: EntityConfigModuleActionType;
};

export type EntityConfigPlanResponse = {
  meta: {
    plan: {
      resource_actions: EntityConfigResourceAction[];
      module_actions: EntityConfigModuleAction[];
    };
    hash: string;
  };
};

export type EntityConfigApplyResponse = {
  meta: {
    success: boolean;
  };
};
